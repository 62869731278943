.roadmap {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.roadmap button {
  margin: 0 5px;
  padding: 5px 10px;
  opacity: 0.2;
  border: none;
  color: white;
  cursor: pointer;
}

.roadmap button.active {
  opacity: 100;
  color: white;
}

@keyframes slideInFromTop {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

.slide-in-from-top1 {
  animation: slideInFromTop 0.5s ease-in-out;
}

.skill_sets {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

@media (min-width: 740px) and (max-width: 1033px) {
  .skill_sets {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@media (max-width: 734px) {
  .skill_sets {
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
}

.project_display {
  width: 45%;
}

@media (min-width: 999px) and (max-width: 1033px) {
  .project_display {
    width: 60%;
  }
}

@media (max-width: 734px) {
  .project_display {
    width: 100%;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.rotate-gradient {
  animation: spin 4s linear infinite;
  animation-timing-function: linear;
  /* Ensure constant speed */
}

@media (min-width: 999px) and (max-width: 1033px) {
  .Login_display {
    width: 90%;
  }
}