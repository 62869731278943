table, th, td {
    border: 1px solid #E8E7E733;
    border-collapse: collapse;
    
  }
  th, td {
    background-color: #2D2D2D;
  }
  table, th, td {
    border: 1px solid #E8E7E733;
    border-collapse: collapse;
    
  }
  th, td {
    background-color: #2D2D2D;
  }
  .apexcharts-menu {
    color: black;
  }
  .apexcharts-tooltip {
    color: black;
  }
  .no-scroll {
    overflow: hidden;
  }