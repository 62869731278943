.inner-shadow {
  box-shadow: inset #38bdf8 0px 0px 50px -10px; 
}
.inner-shadow2 {
  box-shadow: inset #0ea5e9 0px 0px 20px -10px; 
}
.inner-shadow-black {
  box-shadow: inset #000 0px 0px 25px -5px; 
}
/* width */
::-webkit-scrollbar {
  display: none;
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: rgb(30, 30, 30); 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #000; 
}
@keyframes slideInFromTop {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

.slide-in-from-top {
  animation: slideInFromTop 2s ease-in-out;
}


@keyframes slide-in-from-left {
  from {
    transform: translateX(-40%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

.slide-in-from-left {
  animation: slide-in-from-left 1s ease-in-out;
}

.fade-in{
  animation: fadeIN 1s ease-in-out;
}

@keyframes fadeIN{
  0%{opacity: 0;}
  100%{opacity: 1;}
}

.text-custom-shadow {
  text-shadow: 2px 2px 3px rgba(57, 147, 174, 0.8);
}

.bg-moreblack {
  background: linear-gradient(to right, #51CBFFCF 0%, black 40%, black 60%, #51CBFFCF 100%)
}

.truncate-text {
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Limits the content to 2 lines */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis; /* Adds the ellipsis (...) */
  line-height: 1.4; /* Adjust as needed */
  max-height: 2.8em; /* This ensures only 2 lines are shown */
}