.roadmap {
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }
  
  .roadmap button {
    margin: 0 5px;
    padding: 5px 10px;
    opacity: 0.2;
    border: none;
    color: white;
    cursor: pointer;
  }
  
  .roadmap button.active {
    opacity: 100;
    color: white;
  }
  @keyframes slideInFromTop {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
}

.slide-in-from-top1 {
    animation: slideInFromTop 0.5s ease-in-out;
}